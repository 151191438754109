import Screen from '../util/Screen';

export default class PageNav {
  constructor(obj) {
    this.group = obj.group;
    this.trigger = this.group.querySelector('.js-page-nav-trigger');
    this.area = this.group.querySelector('.js-page-nav-area');
    this.body = obj.body;
    this.height;
    this.init();
  }

  init() {
    this.trigger.addEventListener('click', () => {
      const height = this.getHeight();
      if (this.height !== height) {
        this.height = height;
        this.area.style.setProperty('--height', this.height + 'px');
      }
      this.trigger.classList.toggle('-is-active');
      this.area.classList.toggle('-is-active');
      this.body.classList.toggle('-is-nonescroll');
    });
  }

  getHeight() {
    console.log(this.trigger.getBoundingClientRect().top, this.trigger.clientHeight, window.innerHeight);
    return window.innerHeight - this.trigger.getBoundingClientRect().top - this.trigger.clientHeight;
  }
}
