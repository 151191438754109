export const observeLang =()=>{
    function updateClasses() {
        var lang = document.documentElement.lang;
        if (lang === 'en') {
            document.querySelectorAll('.js-en-target').forEach(function(el) {
                el.classList.add('-active');
            });
            document.querySelectorAll('.js-ja-target').forEach(function(el) {
                el.classList.remove('-active');
            });
        } else if (lang === 'ja') {
            document.querySelectorAll('.js-en-target').forEach(function(el) {
                el.classList.remove('-active');
            });
            document.querySelectorAll('.js-ja-target').forEach(function(el) {
                el.classList.add('-active');
            });
        }
    }

    // MutationObserverでlang属性の変更を監視
    var observer = new MutationObserver(function(mutations) {
        mutations.forEach(function(mutation) {
            if (mutation.attributeName === 'lang') {
                updateClasses();
            }
        });
    });

    // 監視の設定
    observer.observe(document.documentElement, {
        attributes: true
    });

    // 初期状態のクラスを設定
    document.addEventListener('DOMContentLoaded', function() {
        updateClasses();
    });

}