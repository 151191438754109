export const form =()=>{
    var submitButton = document.querySelector(".wpcf7-submit");
    var formControls = document.querySelectorAll(".wpcf7-form-control-wrap");

    if (submitButton) {
        submitButton.addEventListener('click', function() {
            formControls.forEach(function(control) {
                control.classList.add("is-show");
            });
        });
    }
}