import SwitchViewport from './ui/SwitchViewport';

// import { ScrollHeader } from './ui/ScrollHeader';
import Screen from './util/Screen';
import MainVisual from './ui/Mainvisual';
import PageNav from './ui/PageNav';
import Table from './ui/Table';
import ColumnsCount from './ui/ColumnsCount';
import Modal from './ui/Modal';
import { SmoothScroll } from './ui/SmoothScroll';
import Slider from './ui/Slider';
import HeaderMenu from './ui/HeaderMenu';
import { form } from './ui/Form';
import { tab } from './ui/Tab';
import { accordion } from './ui/Accordion';
import {observeLang} from './util/ObserveLang';
// import { PageTopScroll } from './ui/PageTopScroll';

document.addEventListener('DOMContentLoaded', () => {
  Screen.init();
  const body = document.querySelector('.js-container');

  const mainVisual = document.querySelector('#js-mainvisual');
  if (mainVisual) {
    new MainVisual(mainVisual);
  }

  const header = document.querySelector('#js-header');
  if (header) {
    new HeaderMenu({
      header: header,
      body: body
    });
  }

  const tableList = document.querySelectorAll('.wp-block-flexible-table-block-table');
  for (let i = 0; i < tableList.length; i++) {
    new Table({
      tableGroup: tableList[i]
    });
  }
  //tab
  tab();
  //form
  form();
  //lang
  observeLang();
});

window.addEventListener('load', () => {
  // 読み込み時のtransition-property: allのちらつき対策
  document.getElementsByTagName('body')[0].classList.remove('preload');
  const body = document.querySelector('.js-container');
  
  const pageNav = document.querySelector('.js-page-nav');
  if (pageNav) {
    new PageNav({
      group: pageNav,
      body: body
    });
  }

  /**
   * カスタムブロック関連
   */
  const columns = document.querySelectorAll('.wp-block-columns');
  for (let i = 0; i < columns.length; i++) {
    new ColumnsCount({
      columns: columns[i],
      columnsItem: '.wp-block-column',
    });
  }

  
  const modal = document.querySelector('.js-modal');
  if (modal) {
    new Modal({
      modal: modal
    })
  }

  // スライダー
  const slider = document.querySelector('#js-main-slider');
  if (slider) {
    new Slider({
      slider: slider,
    })
  }

  /**
   * UI関連
   */
  // アコーディオン
  // const accordion = new Accordion();
  accordion();
  // const accordionGroup = document.querySelectorAll('.js-accordion-group');
  // const accordionArray = [];
  // for (let i = 0; i < accordionGroup.length; i++) {
  //   accordionArray.push(
  //     new Accordion({
  //       accordionGroup: accordionGroup[i],
  //       trigger: '.js-accordion-trigger',
  //       area: '.js-accordion-area',
  //       reference: '.js-accordion-reference'
  //     })
  //   );
  // }

  // アンカーリンクなどのスムーススクロール
  const selector = document.querySelector('.js-page-nav');
  if (selector) {
    new SmoothScroll({
      header: selector, // Default: null
      BREAK_POINT: null, // Default: 768
      DURATION: null, // Default: 1200
    });
  }
  
  // const tableList = document.querySelectorAll('.wp-block-table');
  // for (let i = 0; i < tableList.length; i++) {
  //   new TableStyle(tableList[i]);
  // }

  /**
   * レスポンシブ対応関連
   */
  new SwitchViewport();
});
