import Screen from '../util/Screen';

export default class Modal { 
  constructor(obj) {
    this.modal = obj.modal;
    this.modalTrigger = this.modal.querySelector('.js-modal-trigger');
    this.modalINner = this.modal.querySelector('.js-modal-inner');
    this.triggerText = this.modalTrigger.innerText;
    this.pageNav = document.querySelector('.js-page-nav');
    this.pageNavHeight = this.pageNav.clientHeight;
    this.modalFlag = false;
    this.init();
  }

  init() { 
    this.modalTrigger.addEventListener('click', () => {
      this.modalFlag = !this.modalFlag;
      this.modal.classList.toggle('-is-active', this.modalFlag);
      this.modalINner.classList.toggle('-is-active', this.modalFlag);
      this.height = this.getHeight();
      this.modal.style.setProperty('--modal-h', this.height + 1 + 'px');
    });
    Screen.addScrollObject(this);

    window.addEventListener('click', (e) => {
      if( this.modalFlag && e.target.closest('.js-modal') === null) {
        this.modalFlag = false;
        this.modal.classList.remove('-is-active');
        this.modalINner.classList.remove('-is-active');
      }
    });
  }

  getHeight() {
    return window.innerHeight - this.modal.getBoundingClientRect().top;
  }

  onScroll() {
    if (!this.modalFlag) return;
    const height = this.getHeight();
    if (height !== this.height) {
      this.modal.style.setProperty('--modal-h', height + 1 + 'px');
      this.height = height;
    }
  }
}
