
import KeenSlider from 'keen-slider'

const duration = 8000;

export default class MainVisual {
  constructor(mainVisual) {
    this.mainVisual = mainVisual;
    this.buttons = document.querySelectorAll('.js-slide-button');
    this.playButton = document.querySelector('.js-slide-play-button');
    this.timer = null;
    this.flag = true;
    this.index = null;
    this.slider = null;
    this.isStop = false;
    this.startTime = null;
    this.endTime = null;
    this.loadingTime = null;
    this.init();
  }

  init() {
    this.slider = new KeenSlider("#keen-slider", {
      loop: true,
      defaultAnimation: {
        duration: 1000,
      },
      detailsChanged: (s) => {
        const index = this.index | s.track.details.rel;
        s.slides.forEach((element, idx) => {
          element.classList.toggle("-active", s.slides[index] === element);
          
          this.buttons[idx].classList.toggle("-active", s.slides[index] === element);

          if (s.slides[index] === element) {
            element.classList.add("-moving");
          } else {
            setTimeout(() => {
              element.classList.remove("-moving");
            }, 600);
          }
        });
      },
      slideChanged: (s) => {
        if (this.index === null) return;
        if (this.index === s.track.details.rel) { 
          this.index = null;
          return;        
        }
      },
      renderMode: "custom",
      created: (s) => {
        this.onAnimation(s);
        this.onClicks(s);
      }
    });
  }

  onClicks(s) {
    this.buttons.forEach((button, idx) => {
      button.addEventListener('click', () => {
        clearTimeout(this.timer);
        this.isStop = false;
        this.playButton.classList.remove('-stop');

        const element = document.querySelectorAll('.keen-slider__slide');
        for (let i = 0; i < element.length; i++) {
          element[i].classList.remove('-stop');
          this.buttons[i].classList.remove('-stop');          
        }

        this.slider.animator.stop();
        this.index = idx;
        this.flag = false;
        
        s.moveToIdx(idx);
        this.flag = true;
        this.onAnimation(s);
      });
    });

    this.playButton.addEventListener('click', () => {
      this.isStop = !this.isStop;
      this.playButton.classList.toggle('-stop', this.isStop);
      this.playButton.setAttribute('aria-label', this.isStop ? '再生する' : '停止する');
      const element = document.querySelectorAll('.keen-slider__slide')[this.slider.track.details.rel];
      if (this.isStop) {
        this.endTime = performance.now();
        this.loadingTime = this.endTime - this.startTime;
        clearTimeout(this.timer);
        element.classList.add('-stop');
        this.buttons[this.slider.track.details.rel].classList.add('-stop');
        this.slider.animator.stop();
      } else { 
        element.classList.remove('-stop');
        this.buttons[this.slider.track.details.rel].classList.remove('-stop');
        setTimeout(() => {
          this.slider.next();
          this.onAnimation(s);
        }, duration - this.loadingTime);
      }
    });
  }

  onAnimation(s) {
    if (this.flag) {
      this.timer = setTimeout(() => {
        s.next();
        this.startTime = performance.now();
        this.onAnimation(s);
      }, duration);
    }
  }
}
