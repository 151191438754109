export const tab = () => {
    const tabs = document.querySelectorAll('.js-tab');
    const contents = document.querySelectorAll('.js-tab-content');

    tabs.forEach((tab, index) => {
        tab.addEventListener('click', () => {
            const tabId = tab.getAttribute('aria-controls');
            const additionalTargets = document.querySelectorAll(`[data-tab="${tabId}"]`);

            // Remove active class from all tabs and contents
            tabs.forEach(t => {
                t.classList.remove('-active');
                t.setAttribute('aria-selected', 'false');
                t.setAttribute('tabindex', '-1');
            });
            contents.forEach(c => c.classList.remove('-active'));

            // Add active class to the clicked tab
            tab.classList.add('-active');
            tab.setAttribute('aria-selected', 'true');
            tab.setAttribute('tabindex', '0');

            // Add active class to the corresponding content by id
            const content = document.getElementById(tabId);
            if (content) {
                content.classList.add('-active');
            }

            // Add active class to elements with matching data-tab attribute
            additionalTargets.forEach(target => target.classList.add('-active'));
        });

        // Enable keyboard navigation
        tab.addEventListener('keydown', (e) => {
            let newIndex;
            if (e.key === 'ArrowRight') {
                newIndex = (index + 1) % tabs.length;
                tabs[newIndex].focus();
            } else if (e.key === 'ArrowLeft') {
                newIndex = (index - 1 + tabs.length) % tabs.length;
                tabs[newIndex].focus();
            } else if (e.key === 'Enter' || e.key === ' ') {
                tab.click();
            }
        });
    });
}
