import Screen from '../util/Screen';

export default class Table {
  constructor({ tableGroup }) {
    this.tableGroup = tableGroup;
    this.table = this.tableGroup.querySelector('table');
    this.tbody = this.tableGroup.querySelector('tbody');
    this.tbodyRows = Array.from(this.tbody.querySelectorAll('tr'));
    this.thead = this.tableGroup.querySelector('thead');
    this.tbody = this.tableGroup.querySelector('tbody');
    this.theadRows = Array.from(this.thead.querySelectorAll('tr'));
    this.tbodyRows = Array.from(this.tbody.querySelectorAll('tr'));
    this.colspanHeaders = Array.from(this.tbody.querySelectorAll('th[colspan]'));
    this.init();
  }

  init() {
    this.adjustColspanHeaders();
    this.styleCheckCells();
    this.addScrollInfoBar();
  }

  adjustColspanHeaders() {
    this.colspanHeaders.forEach(th => {
      const colspan = parseInt(th.getAttribute('colspan'), 10);
      const colIndex = Array.from(th.parentNode.children).indexOf(th);

      this.tbodyRows.forEach(row => {
        const headers = row.querySelectorAll('th');

        for (let i = colIndex; i < colIndex + colspan; i++) {
          if (headers[i]) { 
            if (!headers[i]?.getAttribute('colspan')) {
              headers[i].classList.add('-colspan');
            } else {
              break;
            }
          }
        }
      });
    });
  }

  styleCheckCells() {
    this.tbodyRows.forEach((row, index) => {
      if (index === 0) {
        this.totalColumns = 0;
        row.querySelectorAll('th').forEach(th => {        
          this.totalColumns += parseInt(th.getAttribute('colspan') || 1, 10);
        });
      }
      row.querySelectorAll('td').forEach(td => {
        if (td.textContent.includes('●') || td.textContent.includes('〇')) {
          td.classList.add('-check');
        }
      });

      if (!this.tableGroup.classList.contains('-none-scroll')) { 
        row.querySelectorAll('th').forEach(th => {        
          const rect = th.getBoundingClientRect();
          const tableRect = this.table.getBoundingClientRect();
          const offsetLeft = rect.left + window.scrollX - tableRect.left;
          th.classList.add('-sticky');
          th.style.left = `${offsetLeft}px`;
        });
      }
    });

    if (!Screen.getBP(Screen.M)) {
      if (this.totalColumns > 0 && !this.tableGroup.classList.contains('-none-scroll')) {
        this.theadRows.forEach(row => {
          row.querySelectorAll('th.-fixed').forEach(th => {
            const rect = th.getBoundingClientRect();
            const tableRect = this.table.getBoundingClientRect();
            const offsetLeft = rect.left + window.scrollX - tableRect.left;
            th.classList.add('-sticky');
            th.style.left = `${offsetLeft}px`;
          });
        });
        this.tbodyRows.forEach(row => {
          row.querySelectorAll('td.-fixed').forEach(th => {
            console.log('log');
            const rect = th.getBoundingClientRect();
            const tableRect = this.table.getBoundingClientRect();
            const offsetLeft = rect.left + window.scrollX - tableRect.left;
            th.classList.add('-sticky');
            th.style.left = `${offsetLeft}px`;
          });
        });
      }
    }
  }

  addScrollInfoBar() {
    if (this.table.querySelectorAll('.-sticky').length) {
      const scrollInfoBar = document.createElement('div');
      scrollInfoBar.classList.add('wp-block-info-bar');
      this.tableGroup.parentNode.insertBefore(scrollInfoBar, this.tableGroup);

    }
  }
}
