// import Debugger from '../util/Debugger';

/**
 * detailsを閉じるアニメーション関数
 * @param {HTMLElement} content アニメーションさせるコンテンツ
 * @param {HTMLElement} accordion detailsに当たる要素
 */
const closeAnimation = (content, accordion) => {
  content.style.height = `${content.scrollHeight}px`;

  const height = 0;
  const duration = 200;
  let start = null;

  function step(timestamp) {
    if (!start) start = timestamp;
    const progress = timestamp - start;

    content.style.height = `${Math.max(
      content.scrollHeight - (progress / duration) * content.scrollHeight,
      height
    )}px`;

    if (progress < duration) {
      requestAnimationFrame(step);
    } else if (accordion.hasAttribute('open')) {
      accordion.removeAttribute('open');
    }
  }

  requestAnimationFrame(step);
};

/**
 * detailsを開くアニメーション関数
 * @param {HTMLElement} content アニメーションさせるコンテンツ
 * @param {HTMLElement} accordion detailsに当たる要素
 */
const openAnimation = (content, accordion) => {
  content.style.height = '0';

  const height = content.scrollHeight;
  const duration = 200;
  let start = null;

  function step(timestamp) {
    if (!start) start = timestamp;
    const progress = timestamp - start;

    content.style.height = `${Math.min(
      (progress / duration) * height,
      height
    )}px`;

    if (progress < duration) {
      requestAnimationFrame(step);
    } else if (!accordion.hasAttribute('open')) {
      accordion.setAttribute('open', '');
    }
  }

  requestAnimationFrame(step);
};

/**
 * MutationObserverを使用して、DOMの変更を検知
 * @param {Array} mutationRecords - DOMの変更を表すMutationRecordの配列
 */
const observer = new MutationObserver((mutationRecords) => {
  for (const record of mutationRecords) {
    const contentElem = record.target.querySelector('.js-content');

    if (record.target.hasAttribute('open')) {
      record.target.classList.add('-is-opened');
      openAnimation(contentElem, record.target);
    }
  }
});

const accordionElems = document.querySelectorAll('.js-details');

export const accordion = () => {
  for (const accordionElem of accordionElems) {
    const summaryElem = accordionElem.querySelector('.js-summary');
    const contentElem = accordionElem.querySelector('.js-content');

    summaryElem.addEventListener('click', (e) => {
      if (accordionElem.classList.contains('-is-opened')) {
        // すぐopen属性が消えるとアニメーションが効かないためpreventDefault()
        e.preventDefault();
        accordionElem.classList.remove('-is-opened');

        closeAnimation(contentElem, accordionElem);
      } else {
        accordionElem.classList.add('-is-opened');

        openAnimation(contentElem, accordionElem);
      }
    });

    observer.observe(accordionElem, {
      attributes: true,
      attributeFilter: ['open'],
    });
  }
};

// import Screen from "../util/Screen";

// export default class Accordion {
//   constructor (obj) {
//     this.accordionGroup = obj.accordionGroup;
//     this.trigger = this.accordionGroup.querySelector(obj.trigger);
//     this.area = this.accordionGroup.querySelector(obj.area);
//     this.reference = this.accordionGroup.querySelector(obj.reference);
//     this.init();
//   }

//   init () {
//     this.setOpen();
//     this.onClickTrigger();
//   }

//   toBoolean (data) {
//     return data.toLowerCase() === 'true';
//   };

//   // アコーディオンコンテンツの高さ取得
//   getHeight () {
//     return this.reference.clientHeight;
//   }

//   // ページロード時の設定
//   setOpen () {
//     if (this.toBoolean(this.accordionGroup.dataset.current) && Screen.getBP(Screen.M)) {
//       this.area.style.height = 'auto';
//       this.area.classList.add('-open');
//       this.trigger.classList.add('-open');
//     } else {
//       this.area.style.height = 0;
//       this.area.style.overflow = 'hidden';
//       this.accordionGroup.dataset.current = false;
//     }
//   }

//   // トリガーイベント
//   onClickTrigger () {
//     this.trigger.addEventListener('click', (e) => {
//       e.preventDefault();

//       if (!this.toBoolean(this.accordionGroup.dataset.current)) {
//         this.area.style.height = `${this.getHeight()}px`;
//         this.area.style.overflow = 'hidden';
//         this.area.classList.add('-open');
//         this.accordionGroup.dataset.current = true;
//         this.trigger.classList.add('-open');

//         const func = () => {
//           this.area.style.overflow = 'visible';
//           this.area.style.height = 'auto';
//           this.area.removeEventListener('transitionend', func);
//         };
//         this.area.addEventListener('transitionend', func, false);
//       } else {
//         this.area.style.height = `${this.getHeight()}px`;
//         this.area.style.overflow = 'hidden';
//         this.trigger.classList.remove('-open');

//         setTimeout(() => {
//           this.area.style.height = '0';
//           this.area.classList.remove('-open');
//           this.accordionGroup.dataset.current = false;
//         });
//       }
//     });
//   }
// }
