/**
 * Viewportが375px以下の場合に、meta viewportのcontentをwidth=375にする
 */
export default class SwitchViewport {
  constructor() {
    this.init();
  }

  init() {
    this.onCheckViewport();
    addEventListener('resize', this.onCheckViewport, false);
  }

  onCheckViewport() {
    const viewport = document.querySelector('meta[name="viewport"]');
    const value =
      window.outerWidth > 375
        ? 'width=device-width,initial-scale=1'
        : 'width=375';
    if (viewport.getAttribute('content') !== value) {
      viewport.setAttribute('content', value);
    }
  }
}
