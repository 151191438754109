import KeenSlider from 'keen-slider'
import Screen from "../util/Screen";

let num = 1;

export default class Slider {
  constructor(obj) {
    this.slideGroupName = '.js-main-slider-group';
    this.sliderName = '#js-main-slider';
    this.slider = obj.slider;
    this.playButton = document.querySelector('.js-main-slide-button');
    this.animation;
    this.timer = 25000;
    this.isStop = false;
    this.windowW = window.innerWidth;
    this.init();
  }

  init() {
    this.setClone();
    this.setSlide();
    this.toggleSlide();
    Screen.addResizeObject(this);
  }

  setSlide() {
    const repeatOften = () => {
      num = num < 1 ? Math.min(num -= 1 / 1600, 1) : 0;
      requestAnimationFrame(repeatOften);
    }
    const animation = Screen.getBP(Screen.M) ? {
      duration: this.timer, easing: (t) => {
        return t;
      }
    } : {
      duration: this.timer, easing: (t) => {
        return t;
      }
      }
    
    this.animation = new KeenSlider(this.sliderName, {
      loop: true,
      drag: false,
      rubberband: false,
      slides: { perView: "auto" },
      created(s) {
        requestAnimationFrame(repeatOften);
        s.moveToIdx(1, true, animation)
      },
      updated(s) {
        s.moveToIdx(s.track.details.abs + 1, true, animation)
      },
      animationEnded(s) {
        s.moveToIdx(s.track.details.abs + 1, true, animation)
      }
    });
  }

  setClone() {
    document.querySelectorAll(this.slideGroupName).forEach((elm, index) => {
      const items = elm.querySelectorAll('.keen-slider__slide');
      const contentWidth = elm.clientWidth;
      const itemWidth = items[0].clientWidth * items.length;
      const ratio = itemWidth / contentWidth;

      if (ratio >= 1.5) return;

      const loopCount = 1.5 / ratio;
      for (let i = 1; i < loopCount; i++) {
        for (let j = 0; j < items.length; j++) {
          this.slider.appendChild(items[j].cloneNode(true));
        }
      }
    })
  }

  toggleSlide() {
    this.playButton.addEventListener('click', () => {
      this.isStop = !this.isStop;
      this.playButton.classList.toggle('-stop', this.isStop);
      if (this.isStop) {
        this.animation.animator.stop();
      } else { 
        const animation = {
          duration: this.timer, easing: (t) => {
            return t;
          }
        }
        this.animation.moveToIdx(this.animation.track.details.abs + 1, true, animation);
      }
    });
  }

  onResize() {
    if (Screen.getBP(Screen.M)) {
      if (this.windowW < window.innerWidth) {
        this.animation.destroy();
        this.setClone();
        this.setSlide();
      }
    }
  }
}
