import Screen from "../util/Screen";

export default class HeaderMenu {
  constructor(obj) {
    this.header = obj.header;
    this.headerMenu = this.header.querySelector('.js-header-menu');
    this.headerTrigger = this.header.querySelector('.js-header-trigger');
    this.openFlag = false;
    this.body = obj.body;
    this.init();
  }

  init() {
    this.headerTrigger.addEventListener('click', () => {
      this.openFlag = !this.openFlag;
      this.toggleMenu();
    });
  }

  toggleMenu() {
    this.headerMenu.classList.toggle('-is-open', this.openFlag);
    this.headerTrigger.classList.toggle('-is-open', this.openFlag);
    this.body.classList.toggle('-is-nonescroll', this.openFlag);
  }
}
